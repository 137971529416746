@use 'animate.css';

@use "scss/fonts";
@use "scss/home";

@use "primeicons/primeicons.css";

@use "quill/dist/quill.core.css" as *;
@use "quill/dist/quill.snow.css" as *;

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  background: #ffffff;
  color: #1b2131;
  font-size: 1rem;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  // Default Font
  // font-family: 'Calibri', sans-serif;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.row {
  display: flex;

  &.responsive {
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.m-hide {
  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

.normal-hide {
  @media screen and (min-width: 800px) {
    display: none !important;
  }
}

// ScrollBar

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #252933;
}

::-webkit-scrollbar-thumb {
  background: #4e5974;
}

::-webkit-scrollbar-thumb:hover {
  background: #495676;
}

.profile-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #384465;
  text-transform: uppercase;
}

.auto-width {
  width: auto !important;
}

.global-create-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-create-btn {
  max-width: none;
  width: auto !important;
  transition: 0.4s;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  border: 1px solid #384465;

  & > i {
    transition: 0.4s;
    font-size: 1.5rem;
    width: auto;
  }
  & > span {
    width: auto;
    transition: 0.4s;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #384465;
    margin-left: 0.5rem;
  }

  &:hover {
    & > i {
      scale: 1.1;
      color: #fff;
    }
    & > span {
      scale: 1.1;
      color: #fff;
    }
    background: #1b2131;
    cursor: pointer;
  }
}

.title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.offset {
  padding: 1rem 10% !important;

  @media screen and (min-width: 1500px) {
    padding: 1rem 15% !important;
  }

  @media screen and (min-width: 2000px) {
    padding: 1rem 20% !important;
  }
}

p-button {
  width: auto !important;
}

p-progressSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-galleria {
  border-style: none !important;
}

.p-galleria-mask {
  background: rgba(0, 0, 0, 0.9) !important;
}

.p-fluid {
  width: 100% !important;
}

.p-password {
  width: 100% !important;
}

.p-inputtext {
  width: 100% !important;
}

p-autocomplete {
  width: 100% !important;
}

.quill-content {
  width: 100% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}


@media screen and (max-width: 750px) {
  .p-inputgroup {
    flex-direction: column !important;
    gap: 0.25rem;
  }
}

.icon-button {
  &:hover {
    cursor: pointer;
  }
}
